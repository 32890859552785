import '@/lib/jquery-setup'
import 'slick-carousel'

(function($){
    $(function(){

        // TABLES!!!
        //--> For prettier tables...
        // wraps scrollable tables on medium and smaller viewports
        function scrollWrap() {
            $('table, .table-flex').wrap('<div class="scroll-wrapper"></div>');
            $('.scroll-wrapper').before( '<div class="scroll-header">Swipe to view more content</div>' );
        }
        scrollWrap();
        // show or hide table scroll header based on if overflowing its parent
        function handleScrollResize() {
            $('table, .table-flex').each(function(index) {
                var table = $(this);
                var tableWidth = table.outerWidth();
                var parentWidth = table.parent().outerWidth();
                var scrollHeader = table.parent().prevAll('.scroll-header:first');

                if (tableWidth > parentWidth) {
                    // element has overflow
                    scrollHeader.show();
                    table.parent().addClass('state-overflow');
                } else {
                    // element doesn't overflow
                    scrollHeader.hide();
                    table.parent().removeClass('state-overflow');
                }
            })
        }
        $(window).on("resize",function () {
            handleScrollResize();
        });
        $(document).ready( function () {
            handleScrollResize();
        });

        //--> Table block enhancements w/registered styles
        // ...to make the header a single centered column
        if ($(".wp-block-table").hasClass("is-style-single-column-header")) {
            const colAmt = $("table > tbody > tr:first > td").length;
            $(".is-style-single-column-header th:first").attr("colspan", colAmt);
        }
        // ...to make the footer a single centered column
        if ($(".wp-block-table").hasClass("is-style-single-column-footer")) {
            const colAmt = $("table > tbody > tr:last > td").length;
            $(".is-style-single-column-footer th:last").attr("colspan", colAmt);
        }


        // Mobile Nav icon
        $('.mobile-i-button').click(function () {
            $('body').toggleClass('disable-scroll');
            $('html').toggleClass('disable-scroll');
            $(this).toggleClass('ion-menu-sharp');
            $(this).toggleClass('ion-close');
            $('.the-nav').toggleClass('bg');
            $('.the-nav').toggleClass('mobile-nav');
            $('.desktop-nav').slideToggle(200);
            $('#menu-main-navigation .menu-item.menu-item-has-children > i.expanded').removeClass('expanded')
            $('#menu-main-navigation .sub-menu.show').removeClass('show');
        });


        // Mobile Nav icon
        $('#menu-main-navigation .menu-item.menu-item-has-children > i').click(function () {
            // console.log($(this).classList())
            if($(this).hasClass('active')){
                $(this).removeClass('active').removeClass('expanded')
                $(this).siblings('.sub-menu').removeClass('show');
            } else {

                $('#menu-main-navigation .menu-item.menu-item-has-children > i.active').removeClass('active').removeClass('expanded')
                $('#menu-main-navigation .sub-menu.show').removeClass('show');
                $(this).toggleClass('expanded active');
                $(this).siblings('.sub-menu').toggleClass('show');
            }
        });


            if (topOfPage()) {
                $('.fixed-nav').addClass('at-the-top');
                $('.ion-menu-sharp').addClass('at-the-top');
            } else {
                $('.fixed-nav').removeClass('at-the-top');
                $('.ion-menu-sharp').removeClass('at-the-top');
            }

        $(window).scroll(function () {
            if (topOfPage()) {
                $('.fixed-nav').addClass('at-the-top');
                $('.ion-menu-sharp').addClass('at-the-top');
            } else {
                $('.fixed-nav').removeClass('at-the-top');
                $('.ion-menu-sharp').removeClass('at-the-top');
            }
        });
        
        function topOfPage() {
            if ($(window).scrollTop() <= 10) {
                return true;
            } else {
                return false;
                $('.fixed-nav').removeClass('at-the-top');
                $('.ion-menu-sharp').removeClass('at-the-top');
            } 
        }  
        
        
        // Accordion javascript
        var isAnimating = false;
        
        //open first accordion items on load. can't hard code this since its in a cms, der.

        $('.single-accord:first-of-type .display-status').addClass('current').show();
        $('.single-accord:first-of-type .accordion').addClass('change-color');
        $('.single-accord:first-of-type span').addClass('rotate-icon');


        // BEGIN - for previous accordions (not blocks)
        $('.single-accord:first-of-type .b').addClass('current').show();
        $('.single-accord .h').on('click', function (e) {
                var $curAccord = $(this).parent().parent();
                if (!isAnimating) {
                    isAnimating = true;
                    if ($(this).next().hasClass('current')) {
                        $curAccord.find('.single-accord .h .plus').removeClass('rotate-icon');
                        $curAccord.find('.single-accord .accordion').removeClass('change-color');
                        $(this).next().removeClass('current').slideUp(function () {
                            isAnimating = false;
                        });
                    } else {
                        $curAccord.find('.single-accord .b').slideUp().removeClass('current');
                        $curAccord.find('.single-accord .h .plus').removeClass('rotate-icon');
                        $curAccord.find('.single-accord .accordion').removeClass('change-color');
                        $(this).next().addClass('current').slideToggle(function () {
                            isAnimating = false;
                        });
                        $(this).find('span').addClass('rotate-icon');
                        $(this).find('.accordion').addClass('change-color');
                    }
                }
        });
        // END

        $('.single-accord .title').on('click', function (e) {
            //limiting scope of interaction to just one carousel when more than one carousels are on the page.
            var $curAccord = $(this).parent().parent();
            if (!isAnimating) {
                isAnimating = true;
                if ($(this).next().hasClass('current')) {
                    $curAccord.find('.single-accord .title .plus').removeClass('rotate-icon');
                    $curAccord.find('.single-accord .accordion').removeClass('change-color');
                    $(this).next().removeClass('current').slideUp(function () {
                        isAnimating = false;
                    });
                } else {
                    $curAccord.find('.single-accord .display-status').slideUp().removeClass('current');
                    $curAccord.find('.single-accord .title .plus').removeClass('rotate-icon');
                    $curAccord.find('.single-accord .accordion').removeClass('change-color');
                    $(this).next().addClass('current').slideToggle(function () {
                        isAnimating = false;
                    });
                    $(this).find('span').addClass('rotate-icon');
                    $(this).find('.accordion').addClass('change-color');
                }
            }
        });

        // Slick Library
        // https://kenwheeler.github.io/slick/
        // check if blocks is being used & there's a price container
        if ($("body").hasClass("page-template-block-based-landing-page") && $("div.price-card-container > .carousel").length) {

            // find the card containers and add the Slick library to it
            $("div.price-card-container > .carousel").slick({
                dots: false,
                centerMode: false,
                infinite: true,
                speed: 1000,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1800,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                            centerMode: false,
                        }
                    },

                    {
                        breakpoint: 1060,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                            centerMode: false,
                        }
                    },

                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        }


    });


    // For prettier tables...
    $(function(){

        // wraps scrollable tables on medium and smaller viewports
        function scrollWrap() {
            $('table, .table-flex').wrap('<div class="scroll-wrapper"></div>');
            $('.scroll-wrapper').before( '<div class="scroll-header">Swipe to view more content</div>' );
        }
        scrollWrap();

        // show or hide table scroll header based on if overflowing its parent
        function handleScrollResize() {
            $('table, .table-flex').each(function(index) {
                var table = $(this);
                var tableWidth = table.outerWidth();
                var parentWidth = table.parent().outerWidth();
                var scrollHeader = table.parent().prevAll('.scroll-header:first');

                if (tableWidth > parentWidth) {
                    // element has overflow
                    scrollHeader.show();
                    table.parent().addClass('state-overflow');
                } else {
                    // element doesn't overflow
                    scrollHeader.hide();
                    table.parent().removeClass('state-overflow');
                }
            })
        }
        $(window).on("resize",function () {
            handleScrollResize();
        });
        $(document).ready( function () {
            handleScrollResize();
        });

    });
})(jQuery)
